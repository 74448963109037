@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-confirmation-dialog-card {
  &.v-card {
    position: relative;
    max-width: var(--modal-window-medium-width);
    padding: var(--dialog-padding);
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-lg);
    opacity: 1;

    ::v-deep .v-icon {
      font-size: var(--font-size-h1);
    }
    .v-card__text {
      font-size: var(--font-size-norm);
    }
    .heading,
    p {
      text-align: center;
    }

    &,
    .v-icon,
    .v-card__text,
    .v-card__actions,
    .v-expansion-panel-content {
      color: var(--color-text-theme);
    }

    .v-card {
      // Card Header
      &__title {
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: var(--dialog-padding);
        word-break: break-word;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
